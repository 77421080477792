<template>
	<div class="app-container">
		<!-- filter -->
		<div class="filter-container">
			<div>
				<!-- 关键字搜索 -->
				<div class="filter-item">
					<label class="label">退款单号:</label>
					<el-input class="input-item" style="margin-left:5px;width:250px" v-model="searchData.refundCode"
					@keyup.enter.native="search" placeholder="请输入售后单号"></el-input>
				</div>

				<div class="filter-item">
					<label class="label">订单编号:</label>
					<el-input class="input-item" style="margin-left:5px;width:250px" v-model="searchData.orderNo" @keyup.enter.native="search"
					placeholder="请输入订单编号"></el-input>
				</div>
				<div class="filter-item">
					<label class="label">退款状态:</label>
					<el-select class="option-item" v-model="searchData.refundStatus">
						<el-option label="全部" :value="null"></el-option>
						<el-option v-for="item in refundStatus" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
			</div>
			<div>
				<!-- 时间筛选 -->
				<div class="filter-item">
					<label class="label">退款申请时间:</label>
					<el-date-picker v-model="applyTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
					end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<div class="filter-item">
					<label class="label">退款完成时间:</label>
					<el-date-picker v-model="finishTime" type="datetimerange" range-separator="～" start-placeholder="开始日期"
					end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
				</div>
				<!-- button -->
				<div class="filter-item">
					<el-button type="primary" style="width:100px;margin-right:20px;" @click="search">查询</el-button>
					<button-permissions :datas="'refundAfterSaleOrderExport'">
						<el-button type="primary" style="width:100px;" @click="groupExport" :loading='exportLoading'>导出</el-button>
					</button-permissions>
				</div>
			</div>
		</div>
		<!-- table -->
		<div class="table-container">
			<el-table :data="tableData" v-loading="loading" border>
				<el-table-column prop="createTime" label="申请时间"></el-table-column>
				<el-table-column prop="payNo" label="交易单号"></el-table-column>
				<el-table-column prop="refundCode" label="售后单号"></el-table-column>
				<el-table-column prop="orderNo" label="订单号"></el-table-column>

				<el-table-column label="退款金额">
					<template slot-scope="scope">
						<div>¥{{scope.row.refMoney}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="refundTime" label="完成时间"></el-table-column>
				<el-table-column label="退款类型" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.isAllRefund==1?'全退':'部分退'}}</div>
					</template>
				</el-table-column>
				<el-table-column label="退款方式" width="100">
					<template>
						<div>原路返回</div>
					</template>
				</el-table-column>
				<el-table-column label="退款状态" width="100">
					<template slot-scope="scope">
						<div>{{scope.row.refundStatus == 0?'待审核':scope.row.refundStatus == 1?'退款成功':'退款失败'}}</div>
					</template>
				</el-table-column>

				<el-table-column label="操作" width="170">
					<template slot-scope="scope">
						<button-permissions :datas="'refundAfterSaleOrderToDo'">
							<div class="table-button" @click="handleDetailShow(scope.row)">{{(scope.row.refundStatus == 1 || scope.row.refundStatus == 2)?'查看详情':'去处理'}}</div>
						</button-permissions>
						<button-permissions :datas="'refundAfterOrderBack'" v-if="scope.row.refundStatus == 2">
							<div v-if="!scope.row.isRollback" class="table-button" @click="handleAfterBack(scope.row)">回滚</div>
							<div v-else class="table-button" style="color: #FF4D51;">已回滚</div>
						</button-permissions>
					</template>
				</el-table-column>
			</el-table>

			<el-pagination v-if="searchData.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
			@current-change="handleCurrentChange" :current-page="searchData.pageNo" :page-sizes="[10, 20, 30, 40, 50]"
			:page-size="searchData.size" layout="total, sizes, prev, pager, next, jumper" :total="searchData.total"></el-pagination>
		</div>
	</div>
</template>

<script>
	import apiList from '@/api/other'
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		afterSaleBack
	} from '@/api/wyUsedInterface'
	export default {
		name: 'refundAfterSaleOrder',
		components: {
			buttonPermissions
		},
		data() {
			return {
				applyTime: '', //时间
				finishTime: '', //退款时间
				searchData: {
					orderNo: '', //订单编号
					applyStartTime: '', //申请开始时间
					applyEndTime: '', //申请结束时间
					finishStartTime: '', //退款完成开始时间
					finishEndTime: '', //退款完成结束时间
					searchType: '0', //订单类型
					refundCode: '', //退款单号
					refundStatus: null, //退款状态: 0:待审核 1:退款成功 2：退款失败
					total: 0,
					pageNo: 1,
					size: 20
				},
				loading: false,
				exportLoading: false, //导出按钮加载状态
				refundStatus: [{
					value: 0,
					label: '待审核'
				}, {
					value: 1,
					label: '退款成功'
				}, {
					value: 2,
					label: '退款失败'
				}],
				tableData: [],
			}
		},
		async beforeMount() {
			this.getList();
		},
		computed: {

		},
		methods: {
			// 获取列表数据
			async getList() {
				this.loading = true
				try {
					let data = this.searchData;
					data.pageNo = this.searchData.pageNo;
					data.pageSize = this.searchData.size;
					data.applyStartTime = ''; //申请开始时间
					data.applyEndTime = ''; //申请结束时间
					data.finishStartTime = ''; //支付开始时间
					data.finishEndTime = ''; //支付结束时间
					if (this.applyTime) {
						data.applyStartTime = this.applyTime[0];
						data.applyEndTime = this.applyTime[1];
					}
					if (this.finishTime) {
						data.finishStartTime = this.finishTime[0];
						data.finishEndTime = this.finishTime[1];
					}
					let result = '';

					result = await apiList.afterSaleAllRefundList(data);

					this.tableData = result.data.records;
					this.searchData.total = result.data.total;
				} catch (error) {
					console.log(error);
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			//搜索
			search() {
				this.searchData.pageNo = 1
				this.searchData.isDownload = false; //下载文件标记
				if (!this.searchData.total) {
					this.searchData.size = 20
				}
				this.getList()
			},
			// 导出
			async groupExport() {
				this.exportLoading = true;
				let data = this.searchData;
				data.isDownload = true; //下载文件标记
				await apiList.afterSaleAllRefundListExport(data);
				this.$message({
					type: 'success',
					message: '导出成功，请稍后至下载中心查看!'
				});
				this.exportLoading = false;
			},
			//跳转至详情
			handleDetailShow(row) {
				let params = {
					id: row.afterSaleId
				}
				if (row.type == 3) {
					this.publicToDetails(params, '/order/refundAfterSale/exChangeRefundDetails', this);
				} else {
					this.publicToDetails(params, '/order/refundAfterSale/refundDetails', this);
				}
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.searchData.size = val;
				this.getList();
			},
			// 翻页
			handleCurrentChange(val) {
				this.searchData.pageNo = val;
				this.getList();
			},
			async handleAfterBack(row) {
				let data = {
					afterSaleId: row.afterSaleId
				}
				let res = await afterSaleBack(data);
				if (res.success) {
					// this.searchData.pageNo = 1
					// this.searchData.size = 20
					this.getList();
					this.$message({
						type: 'success',
						message: '回滚成功'
					});
				} else {
					this.$message({
						type: 'error',
						message: res.alertMsg
					});
				}
			}
		}
	}
</script>

<style lang="less" scoped>
	.app-container {
		background-color: #fff;
	}

	.input-item {
		margin-left: 5px;
	}

	.table-button {
		color: #409eff;
		display: inline-block;
		margin-right: 20px;
		cursor: pointer;
	}

	/deep/.el-table__fixed-body-wrapper {
		background: #ffffff;
	}

	/deep/.el-table__fixed-header-wrapper {
		background: #f8f8f9;
	}
</style>
